import { authConstants } from '../../actions/auth/actionTypes';
import { UserStorageHelper } from '../../../helpers';
import produce from 'immer';
import {
    FilesSharing,
    MappedLicenseStatusBody,
    NextcloudUser,
    SettingsItem,
} from 'nextcloud-api';

const user = UserStorageHelper.storeGetUser();

export type UserConfig = {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    userInfo: any;
    ping: any;
    status: any;
    /* eslint-enable @typescript-eslint/no-explicit-any */
    licenseStatus: MappedLicenseStatusBody;
};

const initialState = {
    loggedIn: Boolean(user),
    loginFailed: false,
    logout: false,
    user: (user ?? null) as NextcloudUser | null,
    userConfig: null as UserConfig | null,
    userSettings: null as SettingsItem[] | null,
    versionSettings: null,
    filesSharingSettings: null as FilesSharing | null,
    passwordPolicySettings: null,
    theme: null,
    passwordDialogSkipped: false,
};

/*
user {
    "server":"https:\/\/cloud.example.com",
    "loginName":"username",
    "appPassword":
    "yKTVA4zgxjfivy52WqD8kW3M2pKGQr6srmUXMipRdunxjPFripJn0GMfmtNOqOolYSuJ6sCN"
}
userConfig.ping {
  "id": ,
  "userid": "",
  "dateconnected": ""
}
userConfig.status {
  "Version": "",
  "CurrentUserId": "",
  "App": "sendent",
  "DateLicenseEnd": "2024-06-20",
  "MaxUsers": "",
  "ValidLicense": true,
  "LicenseAction": "",
  "DateGracePeriodEnd": "2024-06-20",
  "MaxGraceUsers": "",
  "CurrentUserCount": ""
}
userConfig.licenseStatus {
  "status": "Current license is valid",
  "statusKind": "valid",
  "dateExpiration": "2024-06-20",
  "email": "",
  "level": "Premium",
  "licensekey": "",
  "dateLastCheck": "2021-09-10"
}
*/

export function authentication(state = initialState, action) {
    console.log('authentication reducer called');
    switch (action.type) {
        case authConstants.LOGIN_SUCCESS:
            console.log('authentication reducer LOGIN_SUCCESS');
            return produce(state, (draft) => {
                draft.loggedIn = true;
                draft.loginFailed = false;
            });
        case authConstants.LOGOUT:
            console.log('authentication reducer LOGOUT');
            return produce(state, (draft) => {
                draft.loggedIn = false;
                draft.logout = true;
                draft.user = null;
            });
        case authConstants.LOGIN_PROCESS:
            console.log('authentication reducer LOGIN_PROCESS');
            return produce(state, (draft) => {
                draft.user = action.payload.user;
            });
        case authConstants.LOGIN_FAILED:
            console.log('authentication reducer LOGIN_FAILED');
            return produce(state, (draft) => {
                draft.loginFailed = true;
            });
        case authConstants.APP_CHANGE_USER_CONFIG:
            console.log('authentication reducer APP_CHANGE_USER_CONFIG');
            return produce(state, (draft) => {
                draft.userConfig = action.payload.userConfig;
            });
        case authConstants.APP_CHANGE_USER_SETTINGS:
            console.log('authentication reducer APP_CHANGE_USER_SETTINGS');
            return produce(state, (draft) => {
                draft.userSettings = action.payload.userSettings;
                draft.versionSettings = action.payload.versionSettings;
                draft.filesSharingSettings =
                    action.payload.filesSharingSettings;
                draft.passwordPolicySettings =
                    action.payload.passwordPolicySettings;
                draft.theme = action.payload.theme;
            });
        case authConstants.APP_APPLY_USER_SETTINGS:
            console.log('authentication reducer APP_APPLY_USER_SETTINGS');
            return produce(state, (draft) => {
                draft.userSettings = action.payload.userSettings;
            });
        case authConstants.PASSWORD_DIALOG_SKIPPED:
            console.log('authentication reducer PASSWORD_DIALOG_SKIPPED');
            return produce(state, (draft) => {
                draft.passwordDialogSkipped =
                    action.payload.passwordDialogSkipped;
            });
        default:
            return state;
    }
}

import * as microsoftTeams from '@microsoft/teams-js';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../..';
import { exchangeTeamsToken } from '../../../services/microsoft/msToken';
import { selectTranslate } from '../../selectors/ui';
import { showNotification } from '../app/actionCreators';
import { AppErrorCode, NotificationStatus } from '../app/actionTypes';
import { setMsalAuthResult } from './actions';

export const reauthenticateMsTeamsThunk = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> =>
    async function thunk(dispatch, getState): Promise<void> {
        const state = getState();
        const translate = selectTranslate(state);

        let teamsToken: string;
        try {
            teamsToken = await microsoftTeams.authentication.getAuthToken();
        } catch (err) {
            dispatch(
                showNotification({
                    status: NotificationStatus.Error,
                    message: translate('ms_auth_fail'),
                    errorCode: AppErrorCode.AuthenticationFailed,
                }),
            );
            return;
        }

        const authRes = await exchangeTeamsToken(teamsToken);

        if (!authRes) {
            dispatch(
                showNotification({
                    status: NotificationStatus.Error,
                    message: translate('ms_auth_fail'),
                    errorCode: AppErrorCode.AuthenticationFailed,
                }),
            );
        }

        dispatch(setMsalAuthResult(authRes));
    };

import { Settings } from 'nextcloud-api';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../store';
import { selectUserSettings } from '../store/selectors/auth';
import { FieldError, FieldErrors } from 'react-hook-form';
import {
    getFirstFormValidationError,
    getFirstValidationError,
} from './validation';
import { Errors, ValidationError } from './model/validation';

export const useSettings = (): Settings | null => {
    const userSettings = useAppSelector(selectUserSettings);

    const settings = useMemo(() => {
        if (!userSettings) {
            return null;
        }
        return new Settings(userSettings);
    }, [userSettings]);

    return settings;
};

export const useMediaQuery = (query: string) => {
    const [matches, setMatches] = useState(matchMedia(query).matches);

    useLayoutEffect(() => {
        const mediaQuery = matchMedia(query);
        const listener = () => setMatches(mediaQuery.matches);
        mediaQuery.addEventListener('change', listener);
        return () => mediaQuery.removeEventListener('change', listener);
    }, [query]);

    return matches;
};

export const useUnmount = (destructor: () => void): void => {
    useEffect(() => {
        return destructor;
    });
};

type UseFormValidationDialogResult = {
    showValidationDialog: boolean;
    setShowValidationDialog: React.Dispatch<React.SetStateAction<boolean>>;
    errorToDisplay: FieldError | null;
};

export const useFormValidationDialog = (
    errors: FieldErrors,
): UseFormValidationDialogResult => {
    const [showValidationDialog, setShowValidationDialog] = useState(false);

    const validationError = getFirstFormValidationError(errors);
    const isValid = !Boolean(validationError);

    useEffect(() => {
        if (isValid) {
            return;
        }

        setShowValidationDialog(true);
    }, [validationError]);

    return {
        showValidationDialog,
        setShowValidationDialog,
        errorToDisplay: validationError,
    };
};

type UseCustomValidationDialogResult<T extends string> = {
    showValidationDialog: boolean;
    setShowValidationDialog: React.Dispatch<React.SetStateAction<boolean>>;
    errors: Errors<T>;
    firstError: ValidationError | null;
    setErrors: (errors: Errors<T>) => void;
};

export const useCustomValidationDialog = <
    T extends string = string,
>(): UseCustomValidationDialogResult<T> => {
    const [errors, setErrors] = useState<Errors<T>>({});
    const [showValidationDialog, setShowValidationDialog] = useState(false);

    const setErrorsWrapper = (errors: Errors<T>) => {
        setErrors(errors);
        const hasError = Boolean(getFirstValidationError(errors));

        if (!hasError) {
            return;
        }

        setShowValidationDialog(true);
    };

    return {
        showValidationDialog,
        setShowValidationDialog,
        errors,
        setErrors: setErrorsWrapper,
        firstError: getFirstValidationError(errors),
    };
};
